import * as React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import {useStaticQuery, graphql, withPrefix} from "gatsby"

const Seo = ({title, description}) => {
    const {site} = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
                description
                siteUrl
              }
            }
          }
    `
    )
    const siteTitle = site.siteMetadata.title
    const siteDescription = description || site.siteMetadata.description

    return (
        <Helmet
            htmlAttributes={{
                lang: `ja`,
                siteType: 'website'
            }}
            title={title || siteTitle}
            titleTemplate={title ? `%s | ${siteTitle}` : siteTitle}
            meta={[
                {
                    name: `description`,
                    content: siteDescription,
                },
                {
                    property: `og:title`,
                    content: title || siteTitle,
                },
                {
                    property: `og:description`,
                    content: siteDescription,
                },
                {
                    property: `og:type`,
                    content: 'website',
                },
                {
                    property: `og:site_name`,
                    content: siteTitle,
                },
                {
                    name: `og:url`,
                    content: site.siteMetadata.siteUrl,
                },
                {
                    name: `theme-color`,
                    content: "#111111",
                },
            ]}
        >
            <link rel="icon" sizes="16x16" href={withPrefix('/favicon.ico')}/>
        </Helmet>
    )
}

Seo.defaultProps = {
    title: null,
    description: null,
}

Seo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
}

export default Seo
